import React from 'react'

import Layout from 'layouts/DefaultLayout'
import SEO from 'components/SEO'

import './how-it-works.module.scss'

import thumbnail from 'images/shift-generic-thumbnail.jpg'
import ContactHero from '../page-components/Contact/ContactHero/ContactHero'
import ContactForm from '../page-components/Contact/ContactForm/ContactForm'

const ContactPage = () => {
    const [category, setCategory] = React.useState('sales')
    return (
        <Layout modalType="general" logoText="Marketing">
            <SEO
                title="Contact us | Shift Marketing"
                description="We look forward to the opportunity to collaborate with you and your organization."
                image={`https://shiftmarketing.io${thumbnail}`}
                canonical={'https://shiftmarketing.io/contact'}
                ogUrl={'https://shiftmarketing.io/contact'}
                siteName={'Contact us | Shift Marketing'}
            />

            <ContactHero changeCategory={cat => setCategory(cat)} />

            <ContactForm category={category} />
        </Layout>
    )
}

export default ContactPage
