import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import cn from 'classnames'

import * as Form from 'components/Form'

import styles from './ContactForm.module.scss'

const ContactForm = (props) => {
    const [industry, setIndustry] = React.useState(null);
    const [country, setCountry] = React.useState(null);
    const [province, setProvince] = React.useState(null);
    const [recaptchaValue, setRecaptchaValue] = React.useState(null);
    // const [errors, setErrors] = React.useState(null)

    const [submitted, setSubmitted] = React.useState(false)
    const [submitting, setSubmitting] = React.useState(false)

    const onRecaptchaChange = React.useCallback((_recaptchaValue) => {
        setRecaptchaValue(_recaptchaValue)
    }, [])

    const handleFormSubmit = React.useCallback(async e => {
        e.preventDefault()

        setSubmitting(true)

        let form = e.target
        const formData = new FormData(form)

        let body = {}
        formData.forEach((value, key) => {body[key] = value})

        const response = await fetch(form.action, {
            method: form.method,
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })

        setSubmitting(false)

        window.dataLayer.push({
            'event': 'ga',
            'eventCategory': 'Conversion',
            'eventAction': 'User Converted',
            'eventLabel': 'Contact Us',
            'eventValue': '1'
        })

        if (!response.ok) {
            alert('Oops something went wrong')
            console.error(response)
            return
        }

        setSubmitted(true)
    }, [])

    let submitButtonLabel = submitting ? 'Sending…' : 'Submit'

    if (submitted) {
        submitButtonLabel = 'Sent'
    }

    return (
        <section className={styles.contactForm}>
            <div className={styles.inner}>
                <div className={styles.form}>
                    <h2 className={styles.title}>
                        We look forward to hearing from you.
                    </h2>

                    <p className={styles.description}>Our team of professionals can answer any questions that you might have regarding our products and campaign process. We look forward to the opportunity to collaborate with you and your organization. </p>

                    <div className={cn("modal-form", styles.formContainer)}>
                        <div className="form-container">
                            <div className="form-container-inner">

                                <form
                                    onSubmit={handleFormSubmit}
                                    name="contact_form"
                                    action={`https://${process.env.GATSBY_API_HOST}/contact`}
                                    method='post'
                                >
                                    <div className="form-row">
                                        <Form.Field label={'Select Industry'}>
                                            <Form.Dropdown
                                                onChange={(value) => setIndustry(value)}
                                                defaultValue={industry}
                                                options={['Higher Education', 'Automotive', 'Non-profit', 'Other']}
                                            />
                                        </Form.Field>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-field js-ajax-form-field">
                                            <label htmlFor="form-company">Company / Institution name</label>
                                            <input defaultValue="" type="text"  name="company" required="required"/>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-col-half">
                                            <Form.Field label={'Country'}>
                                                <Form.Dropdown
                                                    options={['Canada', 'United States']}
                                                    onChange={value => setCountry(value)}
                                                    defaultValue={country}
                                                />
                                            </Form.Field>
                                        </div>

                                        <div className="form-col-half">
                                            <Form.Field label={'Province / State'}>
                                                <Form.Dropdown
                                                    onChange={value => setProvince(value)}
                                                    defaultValue={province}
                                                    options={PROVINCES[country] || []}
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-col-half">
                                            <div className="form-field js-ajax-form-field">
                                                <label htmlFor="form-first-name">First Name</label>
                                                <input defaultValue="" type="text"  name="firstName"
                                                       required="required"/>
                                            </div>
                                        </div>

                                        <div className="form-col-half">
                                            <div className="form-field js-ajax-form-field">
                                                <label htmlFor="form-last-name">Last Name</label>
                                                <input defaultValue="" type="text"  name="lastName"
                                                       required="required"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-col-half">
                                            <div className="form-field js-ajax-form-field">
                                                <label htmlFor="form-email">Email Address</label>
                                                <input defaultValue="" type="email"
                                                       name="emailAddress" required="required"/>
                                            </div>
                                        </div>

                                        <div className="form-col-half">
                                            <div className="form-field js-ajax-form-field">
                                                <label htmlFor="phone-number">Phone Number</label>
                                                <input defaultValue="" type="tel"  name="phoneNumber"
                                                       required="required"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-field">
                                            <label htmlFor="message">Message</label>
                                            <textarea id='message' name='message' placeholder='Enter message' rows={10}/>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-field js-ajax-form-field">
                                            <ReCAPTCHA
                                                sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                                                onChange={onRecaptchaChange}
                                            />
                                        </div>
                                    </div>

                                    <input value={props.category} type="hidden" name="category" />

                                    <div className="form-row-submit">
                                        <button
                                            style={{ margin: '0 auto' }}
                                            className="sh-button-primary __large"
                                            type="submit"
                                            data-text-success="Sent"
                                            disabled={submitting || submitted || recaptchaValue === null}
                                        >
                                            {submitButtonLabel}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.contactInfo}>
                    <div className={styles.contactOffice}>
                        <div className={styles.contactTitle}>
                            Corporate Office & Automotive
                        </div>
                        {/* <div>#8 - 1815 Ironstone Manor </div>
                        <div style={{marginBottom: 30}}>Pickering, Ontario  L1W 3W9</div> */}

                        <div><strong>Ph</strong> 855.227.1717</div>
                        <div><strong>Em</strong> auto@shiftmarketing.io</div>
                    </div>

                    <div className={styles.contactOffice}>
                        <div className={styles.contactTitle}>
                            U.S. Office, Higher Ed & Non-profit
                        </div>
                        {/* <div>#503 - 650 Franklin Street</div>
                        <div style={{marginBottom: 30}}>Schenectady, New York  12305</div> */}

                        <div><strong>Ph</strong> 518.557.0088</div>
                        <div><strong>Em</strong> highered@shiftmarketing.io</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm

const PROVINCES = {
    'Canada': [
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Nova Scotia",
        "Northwest Territories",
        "Nunavut",
        "Ontario",
        "Prince Edward Island",
        "Québec",
        "Saskatchewan",
        "Yukon"
    ],
    'United States': [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District Of Columbia",
        "Federated States Of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Islands",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"
    ]
};
