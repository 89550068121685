import React from 'react'
import cn from 'classnames'

import styles from './ContactHero.module.scss'

const ContactHero = (props) => {
    const [isActive, setIsActive] = React.useState([true, false, false])

    const changeCategory = (cat) => {
        const newIsActive = isActive.slice().map(_ => false);
        newIsActive.splice(cat, 1, true);
        setIsActive(newIsActive);

        props.changeCategory(cat === 0 ? 'sales' : cat === 1 ? 'customer_support' : 'general_inquiries');
    };

    return (
        <section className={styles.contactHero}>
            <div className={styles.title}>
                <strong>Work with the best in direct marketing.</strong>
            </div>

            <div className={styles.label}>To serve you faster, please select one of the options below.</div>

            <div className={styles.categorySelector}>
                <button onClick={() => changeCategory(0)} className={cn(styles.categoryButton, {[styles.isActive]: isActive[0]})}>Sales</button>
                <button onClick={() => changeCategory(1)} className={cn(styles.categoryButton, {[styles.isActive]: isActive[1]})}>Customer Support</button>
                <button onClick={() => changeCategory(2)} className={cn(styles.categoryButton, {[styles.isActive]: isActive[2]})}>General Inquiries</button>
            </div>
        </section>
    );
};

export default ContactHero
